<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox">
      <div class="flex justify-between">
        <!-- 左侧 -->
        <div class="left_box">
          <div class="menuList">
            <div
              v-for="(item, i) in picList"
              :key="item.id"
              @click="handleTab(i, item.typeCode)"
              :class="
                isActive == i
                  ? 'menu_item bgwhite text-cut'
                  : 'menu_item text-cut'
              "
            >
              {{ item.typeName }}
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right_box bbox pt20 plr15 pb20 positionr flex1">
          <div v-if="fileList.length == 0" class="noMessage positiona">
            <img src="../assets/images/kong.png" alt="" width="100" />
            <div class="f14 textc mt15">暂无图片</div>
          </div>
          <div class="list text666 f13 textc mt50" v-else>
            <van-uploader
              v-model="fileList"
              :deletable="false"
              disabled
              :max-count="1"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      picList: [
        { id: 1, typeName: "取样针" },
        { id: 2, typeName: "分析仪" },
        { id: 3, typeName: "试管" },
        { id: 4, typeName: "过滤网" },
        { id: 5, typeName: "稀释槽" },
      ],
      isActive: 0,
      fileList: [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }],
    };
  },
  methods: {
    loadFileList(typeCode) {
      var sendData = {};
      sendData.objectCode = typeCode;
      var that = this;
      this.$http.post("annexFile/queryOne", sendData).then(function (res) {
        if (200 == res.data.code) {
          that.fileList[0].url = res.data.data.fileUrl;
        }
      });
    },
    handleTab(i, typeCode) {
      this.isActive = i;
      this.loadFileList(typeCode);
    },
  },
  created() {
    this.picList = this.$route.query.picList;
    this.loadFileList(this.picList[this.isActive].typeCode);
  },
};
</script>

<style scoped>
/* // 左侧菜单列表 */
.left_box {
  width: 110px;
  height: 100%;
  background-color: #efefef;
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.menuList .menu_item {
  padding: 12px 10px;
  cursor: pointer;
  color: #666;
}
.list >>> .van-uploader__preview-image {
  width: 150px;
  height: 150px;
}
</style>